@font-face {
  font-family: Comfortaa;
  src: url("../assets/fonts/Comfortaa-VariableFont_wght.ttf");
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: PoppinsRegular;
  src: url("../assets/fonts/Poppins-Regular.ttf");
}

/* light color theme begins */

:root {
  --background-dark: #d9d9d9;
  --background-main: #ffffff;

  --primary-dark: #018793;
  --primary-main: #0da5ad;
  /* yes this is valid, rgb of above, used for modified transparency */
  --primary-main-rgb: 13, 165, 173;
  --primary-light: #4bc2c8;

  --text-primary: #000000;
  --text-secondary: #ffffff;

  --danger-dark: #780202;
  --danger-main: #b30000;
  --danger-light: #bf3939;
  --warning: #f8813e;
  --success: #45b860;

  --event-1: #009fad;
  --event-2: #026dac;
  --event-3: #02588a;
  --event-4: #4b45bd;
  --event-5: #0094eb;

  --link-blue: #0d65ad;
}

/* light color theme ends */

/* dark color theme begins */

html[data-theme="dark"] {
  --background-dark: #555;
  --background-main: #444;

  --text-primary: #ffffff;
  --text-secondary: #000000;

  --danger-dark: #b30000;
  --danger-main: #bf3939;
  --danger-light: #cf4d4d;

  --event-1: #009fad;
  --event-2: #026dac;
  --event-3: #02588a;
  --event-4: #4b45bd;
  --event-5: #258bc6;

  --fc-today-bg-color: var(--background-dark);
  --fc-page-bg-color: var(--background-dark);

  --link-blue: #0d65ad;
}

html[data-theme="dark"] .removeFileImg,
html[data-theme="dark"] .delete-ssn-btn,
html[data-theme="dark"] .visible-ssn-btn {
  filter: invert(1);
}

html[data-theme="dark"] .react-datetime-picker__button__icon {
  filter: invert(1);
}

html[data-theme="dark"] .react-calendar__navigation__label:hover,
html[data-theme="dark"] .react-calendar__navigation__label:focus {
  background-color: var(--background-main) !important;
}

html[data-theme="dark"] .react-calendar__tile--now {
  background-color: var(--background-main);
}

/* dark color theme ends */

.react-calendar {
  background-color: var(--background-dark) !important;
}

.react-calendar__navigation__arrow,
.react-calendar__tile,
.react-calendar__navigation__label {
  color: var(--text-primary);
}

.react-calendar__tile:hover {
  background-color: var(--background-dark) !important;
  filter: brightness(0.9);
}

* {
  box-sizing: border-box;
}

body > iframe {
  /* Make iframe that create-react-app generates not clickable */
  pointer-events: none;
}

html,
body,
body > div {
  /* the react root */
  margin: 0;
  padding: 0;
  height: 100%;
  color: var(--text-primary);
}

body {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  background-color: var(--background-main);
}

.page {
  width: 80%;
  margin: 5rem auto auto auto;
}

.page-content {
  position: relative;
}

a {
  text-decoration: none;
}

h2 {
  margin: 0;
  font-size: 24px;
}

hr {
  border: 1px solid var(--primary-main);
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1rem 0;
  padding: 0;
  font-size: 0.9rem;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.heading {
  font-family: PoppinsRegular, sans-serif;
}

.sub-heading {
  color: var(--primary-main);
}

.section-title {
  color: var(--primary-main);
  border-bottom: 1px solid var(--primary-main);
}

.main-btn {
  background-color: var(--primary-main);
  color: var(--text-secondary);
  border-radius: 0.2rem;
  border: none;
  padding: 0.5rem;
}

.submit-btn {
  transition: 0.15s;
  height: 28px;
  width: 25%;
  min-width: 86px;
  background-color: var(--primary-main);
  color: var(--text-secondary);
  border-radius: 0.2rem;
  border: none;
  padding: 0.3rem 0.5rem;
  float: right;
}

.action-btn {
  font-family: inherit;
  color: var(--text-primary);
  border: 1px solid var(--text-primary);
  border-radius: 0.2rem;
  padding: 0.25rem;
  background-color: var(--background-main);
}

.submit-btn.enabled:hover,
.main-btn:hover {
  background-color: var(--primary-dark);
}

.error-txt {
  margin: 0.4rem 0;
  max-width: 250px;
  font-size: 0.9rem;
  color: var(--danger-main);
}

.ssn-reminder {
  font-weight: 600;
  color: var(--danger-main);
}

.css-j5h6pi-MuiPopper-root {
  height: auto !important;
}

.button {
  cursor: pointer;
}

.button:disabled,
.button.disabled {
  opacity: 0.75;
  cursor: default;
}

.bold {
  font-weight: bold;
}

.btn-underline:hover {
  text-decoration: underline;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.textUnderline {
  text-decoration: underline;
}

.inputStyle {
  border-radius: 0.2rem;
  padding: 5px;
  background: var(--background-main);
  border: none;
  box-shadow: 0px 3px 4px var(--background-dark), -1px 0px 5px var(--background-dark);
  cursor: pointer;
  color: var(--text-primary);
}

.react-datetime-picker__wrapper {
  border-radius: 0.2rem;
  padding: 5px;
  background: var(--background-main);
  border: none !important;
  box-shadow: 0px 3px 4px var(--background-dark), -1px 0px 5px var(--background-dark);
  cursor: pointer;
}

/* ARROW */
.arrow {
  border: solid var(--primary-main);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: 0.15s;
  margin-right: 10px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


/* MEDIA QUERIES */

@media only screen and (min-width: 1100px) {
  .page {
    width: 60vw;
  }
}

@media only screen and (max-width: 900px) {
  .page {
    width: 80%;
  }
  header .logo {
    /* display: none; */
    height: 25px;
    width: auto;
  }
  header {
    /* padding: 5px 0; */
    width: 100%;
    margin: 0;
  }
  .main-title {
    margin: 0;
  }
  .wave {
    display: none;
  }
  .nav-menu-container .logo {
    position: absolute;
    top: 2rem;
    height: 40px;
    width: auto;
  }
}

@media only screen and (max-width: 750px) {
  
  html,
  body,
  body > div {
    /* the react root */
    margin: 0;
    padding: 0;
    min-height: 100vh;
    color: var(--text-primary);
}

.page {
  width: 100vw !important;
}

.page-content {
  position: relative;
  width: 100vw;
  margin-left: 0;
  left: 0rem;
}

.table-container {
  width: 100vw !important;
}
.table-container table {
  min-width: 0 !important;
}
}

@media only screen and (max-width: 600px) {
  .page {
    padding-top: 61px;
    margin:0 !important;
    margin-top:2rem !important;
  }
}

@media only screen and (max-width: 500px) {
  li {
    font-size: 0.9rem;
  }
  h2 {
    font-size: 18px;
  }
  .main-title {
    font-size: 1.6em;
  }
  .logo {
    height: 40px;
    width: auto;
  }
}

.bg-img {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 440px) {
  .page {
    width: 95%;
  }
}
